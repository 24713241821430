import { getSiteStripe } from "../../services/faculty/v1/faculty";
import { GET_SITESTRIPE } from "./actionTypes";

export const getSiteStripeMessage = () => async (dispatch) => {
  await getSiteStripe()
    .then((response) => {
      dispatch({ type: GET_SITESTRIPE, payload: response.data });
    })

    .catch((error) => {
      dispatch({ type: GET_SITESTRIPE, payload: error.request });
    });
};