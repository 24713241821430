import React from "react";
import styles from "./SiteStripe.styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState, useEffect } from "react";
import { Typography, Box, useMediaQuery, Button } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { Link } from "@mui/material";
import CardLink from "../../../components/CardLink";
import arrow from "../../../images/arrow.svg";
import { connect } from "react-redux";

const SiteStripe = (props) => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm")); 

  const [userMessage, setUserMessage] = useState("");
  const [displayReadMore, setDisplayReadMore] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [siteStripeWidth, setSiteStripeWidth] = useState(window.innerWidth);

  const isTextClamped = (elm) => elm.scrollHeight > elm.clientHeight;

  useEffect(() => {
    // if manually setting, go to header.jsx and comment out displaySiteStripe conditions for desktop and mobile in jsx return code 
    // setUserMessage(
    //   "Input desired message here."
    // );
    if (
      (props.siteStripe.message !== null && props.siteStripe.message !== undefined) &&
      props.siteStripe.effectiveDate !== undefined &&
      props.siteStripe.expirationDate !== undefined
    ) {
      setUserMessage(
        `${props.siteStripe.message}`
      );
    };
  }, [props.siteStripe]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSiteStripeWidth(window.innerWidth);
  }); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDisplayReadMore(isTextClamped(document.getElementById("info-message")));
  }, [userMessage, siteStripeWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    setReadMore(!readMore);
  };

  return matches ? (
    <>
      <div id="site-stripe-container" className={classes.mainContainer}>
        <div className={classes.containerContentMobile}>
          <Box className={classes.iconContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Box>
          <Box className={classes.textContainer}>
            <Typography
              className={readMore === false && classes.infoText}
              id="info-message"
              data-testid="info-message"
            >
              {`${userMessage}`}
              {/* <Link
                color="#FFFFFF"
                href="https://my.phoenix.edu/kb/article/How-to-configure-use-of-public-DNS-servers"
              >
                {`We recommend trying these steps`}
                <img className={classes.arrowImage} src={arrow} alt="" />
              </Link> */}
            </Typography>

            {displayReadMore === true && (
              <Button
                role="button"
                className={classes.readMoreButton}
                onClick={handleClick}
              >
                {readMore ? "Read less" : "Read more"}
              </Button>
            )}
          </Box>
        </div>
      </div>
    </>
  ) : (
    <>
      <div id="site-stripe-container" className={classes.mainContainer}>
        <div className={classes.containerContent}>
          <Box className={classes.textContainer}>
            <Box className={classes.iconContainer}>
              <InfoOutlinedIcon className={classes.infoIcon} />
            </Box>
            <Typography
              className={readMore === false && classes.infoText}
              id="info-message"
              data-testid="info-message"
            >
              {`${userMessage}`}
            </Typography>
            {/* <CardLink
              whiteText={true}
              content={"We recommend trying these steps"}
              link={
                "https://my.phoenix.edu/kb/article/How-to-configure-use-of-public-DNS-servers"
              }
            ></CardLink> */}
            {displayReadMore === true && (
              <Button
                role="button"
                className={classes.readMoreButton}
                onClick={handleClick}
              >
                {readMore ? "Read less" : "Read more"}
              </Button>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteStripe: state.siteStripe,
  };
};

export default connect(mapStateToProps)(SiteStripe);
