import { React, useEffect, useState } from "react";
import styles from "../helpDeskSuccessMessageStyles";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import facultyPortalTheme from "../../../facultyPortalTheme";
import { 
    useMediaQuery, 
    Box,
    Grid,
    Link,
    Typography,
    Snackbar,
} from "@material-ui/core";
import BlackArrow from "../../../images/BlackArrow.svg";
import { connect } from "react-redux";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { getCaseNumber } from "../../../services/sfapi/v1/sfApiHelpDesk";

const HelpDeskSuccessPage = (props) => {

    document.title = "Faculty Portal Help Desk Successful Submission Page";

    const classes = styles();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));

    const [openSuccess, setOpenSuccess] = useState(false);
    const [recentCaseNumber, setRecentCaseNumber] = useState("");

    // code to handle error messages occuring on submit.
    const renderSnackbar = (snackbarType, message) => (
        <>
        <Snackbar
            anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
            }}
            open={openSuccess}
            autoHideDuration={null}
            onClose={handleClose}
        >
            <SnackbarContentWrapper
            onClose={handleClose}
            variant={snackbarType}
            message={message}
            noRetryButton="true"
            />
        </Snackbar>
        </>
    );

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }
        setOpenSuccess(false);
    };

    useEffect(() => {
        if (props.facultyDemographics.issuerId) {
            const maxRetries = 3; // Maximum number of retries
            let retryCount = 0; // Initialize retry counter
    
            const getCaseNumWithRetry = async (irn) => {
                try {
                    const response = await getCaseNumber(irn);
                    if (response.data && response.data !== "") {
                        setRecentCaseNumber(response.data);
                        console.log("casenum ==" + response.data);
                    } else if (retryCount < maxRetries) {
                        retryCount++;
                        setTimeout(() => getCaseNumWithRetry(irn), 5000); // Retry after 5 seconds
                    } else {
                        console.log("Maximum retries reached. Unable to get case number.");
                    }
                } catch (error) {
                    console.log(error);
                    if (retryCount < maxRetries) {
                        retryCount++;
                        setTimeout(() => getCaseNumWithRetry(irn), 5000); // Retry after 5 seconds
                    } else {
                        console.log("Maximum retries reached. Unable to get case number.");
                    }
                }
            };
    
            getCaseNumWithRetry(props.facultyDemographics.issuerId);
        }
    }, [props.facultyDemographics.issuerId]);

     return (
        <ThemeProvider theme={facultyPortalTheme}>
            <Box
                id="pageContainer"
                role="main"
                className={
                matches
                    ? classes.pageContainer
                    : tabletMatches
                    ? classes.pageContainerTablet
                    : classes.pageContainerMobile
                }
            >
                <Grid
                    container
                    md={12}
                    sm={11}
                    xs={10}
                    className={
                        matches
                        ? classes.mainPageContent
                        : tabletMatches
                        ? classes.mainPageContentTablet
                        : classes.mainPageContentMobile
                    }
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        className={
                        matches
                            ? classes.linkContainer
                            : tabletMatches
                            ? classes.linkContainerTablet
                            : classes.linkContainerMobile
                        }
                    >
                        <Link
                            id="backToDashboardLink"
                            data-testid="back_to_dashboard_link"
                            underline="none"
                            className={classes.backToDashboardLink}
                            href="/"
                        >
                            <img className={classes.arrowImage} src={BlackArrow} alt="" />
                            <Typography>{`Back to Course Dashboard`}</Typography>
                        </Link>
                    </Grid>

                    <Grid
                        item
                        className={
                        matches
                            ? classes.pageHeaderContainer
                            : classes.pageHeaderContainerMobile
                        }
                    >
                        <Box>
                            <Typography
                                id="pageHeader"
                                data-testid="page_header"
                                component="h1"
                                className={
                                matches
                                    ? classes.pageHeader
                                    : tabletMatches
                                    ? classes.pageHeaderTablet
                                    : classes.pageHeaderMobile
                                }
                            >
                                {"Faculty Help Desk"}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid
                        item
                    >
                        <Box
                            sx={
                            matches
                                ? { width: "825px", marginBottom: "184px" }
                                : tabletMatches
                                ? { maxWidth: "596px", marginBottom: "112px" }
                                : { maxWidth: "327px", marginBottom: "96px" }
                            }
                        >
                            <Box sx={{ marginBottom: "16px" }}>
                                <Typography
                                    id="successSubHeader"
                                    data-testid="success_sub_header"
                                    component="h2"
                                    className={
                                    matches ? classes.subHeaderDesktop : classes.subHeaderTablet
                                    }
                                >
                                    {recentCaseNumber ? `Thank you, we've received your submission. It's been assigned case # ${recentCaseNumber}.` : `Thank you, we've received your submission.`}
                                </Typography>
                            </Box>
                                <Box>
                                    <Typography className={classes.infoText}>
                                        {`A Faculty Help Desk Analyst will review and reach out to you.`}
                                    </Typography>
                                </Box>
                            {/* } */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}; 
const mapStateToProps = (state) => {
    return {
      facultyDemographics: state.demographics,
    };
  };

export default connect(mapStateToProps)(HelpDeskSuccessPage);