import {React, useState, useEffect} from "react";
import styles from "./styles";
import { useMediaQuery, Box } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@material-ui/core";
import ProfileBox from "./ProfileBox";
import ProfileInfo from "./ProfileInfo";
import Logo from "./Logo";
import LinkBox from "./links/LinkBox";
import SiteStripe from "./siteStripe/SiteStipe";
import { getSiteStripeMessage } from "../../redux/siteStripe/siteStripeActions";
import { connect } from "react-redux";


const Header = (props) => {
  //Allow for MUI styling
  const classes = styles();

  // Utilize useMediaQuery to create UI view for Mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [displaySiteStripe, setDisplaySiteStripe] = useState(false);

  useEffect(() => {
    props.getSiteStripeMessage();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const effectiveDate = new Date(props.siteStripe.effectiveDate);//MS date is in UTC
    const expirationDate = new Date(props.siteStripe.expirationDate);//MS date is in UTC

    if (
      (props.siteStripe.message !== null && props.siteStripe.message !== undefined) &&
      props.siteStripe.effectiveDate !== undefined &&
      props.siteStripe.expirationDate !== undefined
    ) { 
      if (props.siteStripe.message === "" || props.siteStripe.message.trim() === "") {
        setDisplaySiteStripe(false);
      } else {
        if (
          (effectiveDate <= currentDate && props.siteStripe.expirationDate === null) || 
          (props.siteStripe.effectiveDate === null && expirationDate >= currentDate) ||
          (props.siteStripe.effectiveDate === null && props.siteStripe.expirationDate === null)
        ) {
            setDisplaySiteStripe(true);
        } else {
          if(props.siteStripe.effectiveDate !== null && props.siteStripe.expirationDate !== null) {
            if(effectiveDate <= currentDate && expirationDate >= currentDate) {
              setDisplaySiteStripe(true);
            } else {
              setDisplaySiteStripe(false);
            };
          };
        };
      };
    };
  }, [props.siteStripe]);


  return matches ? (
    <Grid
      container
      direction="column"
      className={classes.headerContainerMobile}
      role="banner"
    >
      <Grid item>
        <ProfileInfo />
      </Grid>
      <Grid item className={classes.navigateContainerMobile} role="navigation">
        <Logo />
        <LinkBox />
      </Grid>
      {displaySiteStripe &&
        <Grid> 
          <SiteStripe />
        </Grid>
      }
    </Grid>
  ) : (
    <>
      <Box className={classes.headerContainer} role="banner">
        <Grid className={classes.subheaderContainer}>
          <ProfileBox />
          <Logo />
          <LinkBox />
        </Grid>
        {displaySiteStripe &&
          <Grid className={classes.subheaderContainer}>
            <SiteStripe />
          </Grid>
        }
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteStripe: state.siteStripe,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSiteStripeMessage: () => dispatch(getSiteStripeMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);