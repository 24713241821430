import { GET_SITESTRIPE } from "./actionTypes";

// eslint-disable-next-line 
export default (state = {}, action) => {
    switch(action.type) {
        case GET_SITESTRIPE :
            return action.payload;  
        default:
            return state;  
    }
}